@import "_normalize.scss";
@import "_mixins.scss";
@import "_variables.scss";
@import "animate.css";
@import "google-map";
@import "bootstrap-grid";
@import "snackbars.scss"; 
@import "mailform"; 



// Fonts
@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css);
@import url(//fonts.googleapis.com/css?family=Raleway:300,400,500,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Montserrat);

$scheme: nth($schemes, 1);
/*========================================================
                      Main Styles
=========================================================*/
body {
  color: #888;
  font: 300 16px/1.6875 $default-font;
  -webkit-text-size-adjust: none;
  background: map-get($scheme, primary);
}

img {
  max-width: 100%;
  height: auto;
}

/*================     Typography     ===================*/

h1, h2, h3, h4, h5, h6, .heading-1, .heading-2, .heading-3, .heading-4, .heading-5, .heading-6 {
  text-transform: uppercase;
  color: #333;
  font-weight: 800;
}
h2, h3, h4, h5, h6, .heading-1, .heading-2, .heading-3, .heading-4, .heading-5, .heading-6 {
  a:hover{
    color: map-get($scheme, primary);
  }
}

h1, .heading-1 {
  font-size: 50px;
  line-height: 1;
  font-weight: 900;
}

h2, .heading-2 {
  font-size: 40px;
  line-height: 1.25;
  @media (max-width: 1199px) {
    font-size: 35px;
  }
  @media (max-width: 479px) {
    font-size: 28px;
  }
}

h3, .heading-3 {
  font-size: 30px;
  line-height: 1.433333;
  @media (max-width: 1199px) {
    font-size: 25px;
  }
}

h4, .heading-4 {
  font-size: 25px;
  line-height: 1.25;
}

h5, .heading-5 {
  font-size: 20px;
  line-height: 1.25;
}

h6, .heading-6 {
  font-size: 16px;
  text-transform: none;
  font-weight: 500;
}

p {

}

p, .marked-list, .list {
  a:hover {
    color: lighten(map-get($scheme, contrast), 20%);
  }

  .fa {
    text-decoration: none;
  }
}

mark {
}

em {
}

small, .small {
  font-size: .8em;
  line-height: 1.25;
}

.big {
  font-size: 1.5em;
}

time {
  display: block;
}

a {
  font: inherit;
  color: inherit;
  text-decoration: none;

  &:focus {
    outline: none;
  }

  &:active {
    background-color: transparent;
  }

  @include transition(.3s);
}

a[href^="tel:"],
a[href^="callto:"]{
  color: inherit;
  text-decoration: none;
  &:hover {
    color: map-get($scheme, primary);
  }
}
a[href^="mailto:"] {
  color: inherit;
  &:hover {
    color: map-get($scheme, primary);
  }
}

.fa {
  line-height: inherit;
}

[class*='fa-'] {
  &:before {
    font-weight: 400;
    font-family: $fa;
  }
}

.page {
  overflow: hidden;
  min-height: 500px;

  .lt-ie9 & {
    min-width: 1200px;
  }
}

/*=======================================================
                       Main Layout
=========================================================*/
/*==================    Offsets      ======================*/
* + h1 {
}

* + h2 {
}

* + h3 {
}

* + h4 {
}

* + h5 {
}

* + h6 {
}

* + p {
  margin-top: 20px;
}

* + img {
  margin-top: 27px;
}

* + .btn {
  margin-top: 22px;
}

* + .inline-list {
  margin-top: 48px;
}

* + .list{
  margin-top: 20px;
}

* + .mailform {
  margin-top: 27px;
}

* + .terms-list{
  margin-top: 20px;
}

* + .resp-tabs{
  margin-top: 20px;
}

* + .row {
  margin-top: 19px;
}

.row + .row {
}

p + p {
  margin-top: 27px;
}

img + p{
  margin-top: 22px;
}

h6 + p{
  margin-top: 14px;
}

.box + .box{
  @media (max-width: 991px) {
    margin-top: 60px;
  }
}

.offset {
  &-0 {
    margin-top: 0 !important;
  }

  &-1 {
    margin-top: 8px;
  }

  &-2 {

  }

  &-3 {
    margin-top: 10px;
  }
}
/*==================    Postfix      ======================*/
//margin-right
.postfix-1 {
}

/*==================    Preffix      ======================*/
//margin-left
.preffix-1 {
}

/*==================    Insets      ======================*/
.inset{ //?????????????????????????????
  padding-right: 0;
  padding-left: 30px;
  @media (max-width: 991px) {
    padding-right: 0px;
    padding-left: 0px;
  }
}

.inset {
  &-1 {//+++++++++++++++++++++++
    padding-left: 56px;
    @media (max-width: 1199px) {
      padding-left: 51px;
    }
    @media (max-width: 991px) {
      padding-left: 15px;
    }
  }

  &-2 {//++++++++++++++++++++++++
    padding-right: 45px;
    @media (max-width: 1199px) {
      padding-right: 30px;
    }
    @media (max-width: 991px) {
      padding-right: 15px;
    }
  }

  &-3 {//++++++++++++++++++++++++++
    padding-top: 90px;
  }

  &-4{//+++++++++++++++++++++++++++++
    padding-left: 45px;
    @media (max-width: 1199px) {
      padding-left: 30px;
    }
    @media (max-width: 991px) {
      padding-left: 15px;
    }
  }

  &-5{//++++++++++++++++++++++++++++
    padding-right: 56px;
    @media (max-width: 1199px) {
      padding-right: 51px;
    }
    @media (max-width: 991px) {
      padding-right: 15px;
    }

  }
  &-6{//++++++++++++++++++++++++++++
    padding-left: 56px;
    @media (max-width: 1199px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

/*==================      Well     ======================*/
.well {
  padding: 55px 0 115px;
}
.well-2 {
  padding: 93px 0 101px;

  &--inset-1{
     padding-bottom: 95px;
  }
}
.well-3{
  padding: 130px 0;
}
.well-4{
  padding-top: 90px;
}
.well-sm-bottom{
  @media (max-width: 991px) {
    padding-bottom: 90px;
  }
}

/*=======================================================
                        Helpers
=========================================================*/
/*================    Basic Helpers    ==================*/
.round {
  border-radius: 50%;
}

.full {
  position: relative;
  width: 100%;
  z-index: 1;
}

/*==================    Text Styling      ======================*/
.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.strike {
  text-decoration: line-through;
}

.uppercase {
  text-transform: uppercase; 
}

.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

.thin {
  font-weight: 100;
}

.light {
  font-weight: 300;
}

.normal {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.sbold {
  font-weight: 600;
}

strong, .bold {
  font-weight: 700;
}

.ubold {
  font-weight: 900;
}

/*==================     Text Align    ======================*/
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

@media (max-width: 1199px) {
  .text-md-center {
    text-align: center;
  }
  .text-md-right {
    text-align: right;
  }
  .text-md-left {
    text-align: left;
  }
}

@media (max-width: 991px) {
  .text-sm-center {
    text-align: center;
  }
  .text-sm-right {
    text-align: right;
  }
  .text-sm-left {
    text-align: left;
  }
}

@media (max-width: 767px) {
  .text-xs-center {
    text-align: center;
  }
  .text-xs-right {
    text-align: right;
  }
  .text-xs-left {
    text-align: left;
  }
}

@media (max-width: 479px) {
  .text-xxs-center {
    text-align: center;
  }
  .text-xxs-right {
    text-align: right;
  }
  .text-xxs-left {
    text-align: left;
  }
}

/*================      Floating      =================*/
.pull-left {
  float: left;
}

.pull-none {
  float: none;
}

.pull-right {
  float: right;
}

@media (max-width: 1199px) {
  .pull-md-none {
    float: none;
  }
  .pull-md-right {
    float: right;
  }
  .pull-md-left {
    float: left;
  }
}

@media (max-width: 991px) {
  .pull-sm-none {
    float: none;
  }
  .pull-sm-right {
    float: right;
  }
  .pull-sm-left {
    float: left;
  }
}

@media (max-width: 767px) {
  .pull-xs-none {
    float: none;
  }
  .pull-xs-right {
    float: right;
  }
  .pull-xs-left {
    float: left;
  }
}

/*================       Colors       ==================*/
.text-default {
  color: map-get($scheme, default);
}

.text-contrast {
  color: map-get($scheme, contrast);
}

.text-primary {
  color: map-get($scheme, primary);
}
/*==================  Backgrounds  ======================*/
.bg-primary {
  background: map-get($scheme, primary);
}
.bg-contrast {
  background: map-get($scheme, contrast);
}
.bg-default{
  background: map-get($scheme, default);
}
.bg-primary-variant-1 {
  background: rgba(map-get($scheme, primary), 0.8);

  color: #FFFFFF;
  h1, h2, h3, h4, h5, h6, .heading-1, .heading-2, .heading-3, .heading-4, .heading-5, .heading-6 {
    color: #FFFFFF;
  }
}
.bg-contrast-variant-1 {
  background: lighten(map-get($scheme, contrast), 20%);
  color: #FFFFFF;
}
.bg-contrast-variant-2 {
  background: rgba(lighten(map-get($scheme, contrast), 20%), 0.8);

  color: #FFFFFF;
  h1, h2, h3, h4, h5, h6, .heading-1, .heading-2, .heading-3, .heading-4, .heading-5, .heading-6 {
    color: #FFFFFF;
  }
}
.bg-contrast-variant-2__before{
  position: relative;
  &:before{
    content: '';
    position: absolute;
    top: 0;
    right: 100%;
    width: 5000px;
    height: 100%;
    background: rgba(lighten(map-get($scheme, contrast), 20%), 0.8);
    @media (max-width: 991px) {
      display: none;
    }
  }
}
.bg-contrast-variant-2__after{
  position: relative;
  &:after{
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    width: 5000px;
    height: 100%;
    background: rgba(lighten(map-get($scheme, contrast), 20%), 0.8);
    @media (max-width: 991px) {
      display: none;
    }
  }
}
.bg-fixed{
  background-attachment: fixed;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  @media (max-width: 1199px) {
    background-attachment: scroll;
  }
  &__mod{
    position: relative;
    *{
      position: relative;
      z-index: 2;
    }
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(lighten(map-get($scheme, contrast), 20%), 0.8);
      z-index: 1;
    }
  }
}
.mobile .bg-fixed,
.tablet .bg-fixed{

}
.bg-img-1{
  background-image: url("../images/parallax1.jpg");
}
.bg-img-2{
  background-image: url("../images/parallax2.jpg");
}
.bg-img-3{
  background-image: url("../images/parallax3.jpg");
}
.bg-img-4{
  background-image: url("../images/parallax4.jpg");
}
.bg-img-5{
  background-image: url("../images/parallax5.jpg");
}
.bg-img-6{
  background-image: url("../images/parallax6.jpg");
}
/*=======================================================
                        Components
=========================================================*/

/*==================     Brand     ======================*/
.brand {
  display: inline-block;
  &_name {
    overflow: hidden;
    display: inline-block;
    color: map-get($scheme, primary);
  }
  &_slogan{
    display: inline-block;
    font-size: 20px;
    line-height: 29px;
    text-transform: uppercase;
    vertical-align: bottom;
    span{
      color: #333;
    }
  }
  &_name + &_slogan {
    margin-top: 0;
  }
}

/*==================    Images     ======================*/
.image-wrap-1 {
  img{
    margin-top: 7px;
  }
}
.image-wrap-2 {
  @include clearfix;
  img{
    float: right;
    max-width: none;
    @media (max-width: 1299px) {
      max-width: 100%;
    }
  }
}
.image-wrap-3 {
  position: relative;
  img{
    position: absolute;
    top: 0;
    right: 100%;
    margin-right: -56px;
    height: 100%;
    max-width: none;
    @media (max-width: 1199px) {
      margin-right: -51px;
    }
    @media (max-width: 991px) {
      position: static;
      margin-right: 0;
      max-width: 100%;
      height: auto;
    }
  }
}
.image-wrap-3-mod {
  position: relative;
  img{
    position: absolute;
    top: 0;
    left: 100%;
    margin-left: -56px;
    height: 100%;
    max-width: none;
    @media (max-width: 1199px) {
      margin-left: -51px;
    }
    @media (max-width: 991px) {
      position: static;
      margin-left: 0;
      max-width: 100%;
      height: auto;
    }
  }
}
.image-wrap-4 {
  position: relative;
  img.img-left{
    position: absolute;
    right: 45px;
    top: 0;
    max-width: none;
    z-index: 0;
    @media (max-width: 1499px) {
      display: none;
    }
  }
}
/*==================     Icons     ======================*/
.icon {
  display: inline-block;
}

.icon.fa-facebook,
.icon.fa-twitter,
.icon.fa-google-plus,
.icon.fa-skype,
.icon.fa-instagram {
  font-size: 18px;
  line-height: 18px;

  &:hover {
    color: lighten(map-get($scheme, contrast), 20%);
  }
}

/*==================    Buttons    ======================*/
.btn {
  display: inline-block;
  background: transparent;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  .insider{
    top: 0;
    left: 0;
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    &:after {
      content: "";
      background-color: #fff;
      width: 100%;
      height: 20px;
      position: absolute;
      left: -100%;
      top: 50%;
      margin-top: -10px;
      @include transform(rotateZ(45deg));
    }
  }

  &:hover {
    .insider{
      &:after {
        @include transition(all 0.5s ease);
        left: 100%;
      }
    }
  }
}

.btn-sm {

}

.btn-md {
  font-weight: 800;
  font-size: 13px;
  line-height: 20px;
  padding: 15px 30px;
}

.btn-lg {

}

.btn-primary {
  color: #FFFFFF;
  background: map-get($scheme, primary);
  &:hover{
    color: map-get($scheme, primary);
    background: rgba(map-get($scheme, primary), 0.22);
  }
  &:active {
    @include box-shadow(1px 1px 5px map-get($scheme, primary));
  }
}

/*==================      Box      ======================*/
$box-gutter: 30px;

.box {
  .box__middle {
    vertical-align: middle;
  }

  .box__bottom {
    vertical-align: bottom;
  }
}

.box {
  .box_left img, .box_right img {
    max-width: none;
  }
  &_left, &_right, &_body {
    padding: 0;
    display: table-cell;
    vertical-align: top;
  }
  .box_left {
    padding-right: $box-gutter;
  }

  .box_right {
    padding-left: $box-gutter;
  }
}

@mixin make-box($resolution) {
  .box-#{$resolution}-clear {
    .box_left img, .box_right img {
      max-width: 100%;
    }
    .box_left,
    .box_right,
    .box_body {
      padding: 0;
      display: block;
      vertical-align: top;
    }
    .box_left {
      padding-bottom: $box-gutter;
    }
    .box_right {
      padding-top: $box-gutter;
    }
  }
}
@media (max-width: 479px) {
  @include make-box("xxs");
}
@media (max-width: 767px) {
  @include make-box("xs");
}
@media (max-width: 991px) {
  @include make-box("sm");
}
@media (max-width: 1199px) {
  @include make-box("md");
}

.box-lg{
  &_left img, &_right img {
    max-width: none;
  }
  &_left, &_right, &_body {
    display: table-cell;
  }

  &_body {
    vertical-align: middle;
    padding: 40px 40px;
    @media (max-width: 479px) {
      padding: 40px 15px;
    }
  }

  @media (max-width: 991px) {
    .box-lg_left img, .box-lg_right img {
      max-width: 100%;
      width: 100%;
    }
    .box-lg_left,
    .box-lg_right,
    .box-lg_body {
      display: block;
      vertical-align: top;
    }
  }
}
/*==================   Index List  ======================*/
.index-list {
  counter-reset: li;

  > li {
    &:first-child{
      > .index-list__counter {
        padding-left: 66px;
      }
    }
    > .index-list__counter {
      padding-left: 106px;
      padding-top: 80px;
      position: relative;
      &:before{
        content: counter(li, decimal);
        counter-increment: li;
        position: absolute;
        left: -5px;
        bottom: 0;
        font: 400 150px/130px $secondary-font;
        color: map-get($scheme, primary);
        opacity: 0.15;
      }
    }
  }
  li.col-md-1:nth-child(12n + 13),
  li.col-md-2:nth-child(6n + 7),
  li.col-md-3:nth-child(4n + 5),
  li.col-md-4:nth-child(3n + 4),
  li.col-md-6:nth-child(2n + 3) {
    clear: left;
  }
}
/*==================  Inline List  ======================*/
.inline-list {
  li {
    display: inline-block;
    & + li {
      margin-left: 14px;
    }
  }
}
/*==================  List  ======================*/
.list{

  li{
    font-weight: 500;
    color: map-get($scheme, primary);
    & + li{
      margin-top: 10px;
    }
  }
}
/*==================  Terms List  ======================*/
.terms-list{
  dt, dd{

  }
  dt + dd{
    margin-top: 13px;
  }

  dd + dt{
    margin-top: 35px;
  }
  & + *{
    margin-top: 27px;
  }
}
/*==================  Contact-info   ======================*/
.contact-info {
  display: inline-block;
  p + dl{
    margin-top: 15px;
  }
  dl{
    text-align: left;
    min-width: 290px;
    @include clearfix;
    @media (max-width: 991px) {
      min-width: 100%;
    }
    dt{
      float: left;
    }
    dd{
      float: right;
    }
    &.email{
      dt, dd{
        float: none;
        display: inline-block;
        a[href^="mailto:"]{
          text-decoration: underline;
        }
      }
    }
  }
}
/*==================  Post   ======================*/
.post{
  overflow: hidden;
  position: relative;
  &:before{
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#333, 0.8);
    z-index: 1;
    position: absolute;
  }
  img{
    width: 100%;
    @include transition(.5s);
  }
  &_cnt{
    h1, h2, h3, h4, h5, h6, .heading-1, .heading-2, .heading-3, .heading-4, .heading-5, .heading-6 {
      color: map-get($scheme, default);
    }
    color: map-get($scheme, default);
    position: absolute;
    z-index: 2;
    top: 50%;
    @include transform(translateY(-50%));
    left: 22%;
    right: 16%;
    @media (max-width: 1799px) {
      left: 10%;
      right: 10%;
    }
    @media (max-width: 1499px) {
      text-align: center;
      left: 15px;
      right: 15px;
      h2{
        font-size: 30px;
      }
    }
    @media (max-width: 991px) {
      h2{
        font-size: 20px;
      }
    }
    @media (max-width: 767px) {
      h2{
        font-size: 35px;
      }
    }
    @media (max-width: 479px) {
      h2{
        font-size: 30px;
      }
    }
  }
  &:hover{
    img{
      @include transform(scale(1.2));
    }
  }
}
/*==================  Quote   ======================*/
.quote{
  position: relative;
  padding-left: 39px;

  &:before{
    position: absolute;
    top: 0;
    left: 0;
    content: '\f10d';
    font: 400 18px/32px $fa;
    color: map-get($scheme, primary);
  }
  q, cite{
    display: block;
  }
  q + cite{
    margin-top: 15px;
  }
}

/*==================  Banner   ======================*/
.banner{
  min-height: 390px;
  padding: 30px 0;
  width: 100%;
  position: relative;
  &_cnt{
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    @include transform(translateY(-50%));
    padding-left: 40px;
    padding-right: 30px;
    @media (max-width: 1199px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  &__mod{
    min-height: 440px;
  }
  &__mod-2{
    .banner_cnt{
      padding-right: 0;
      padding-left: 36px;
      top: 52%;
      @media (max-width: 991px) {
        padding-right: 20px;
        padding-left: 20px;
      }
      @media (max-width: 479px) {
        position: static;
        @include transform(translateY(0%));
        padding-right: 10px;
        padding-left: 10px;
      }
    }
  }
  &__mod-3{
    .banner_cnt{
      top: 45%;
    }
  }
  &__mod-4{
    .banner_cnt{
      padding-right: 40px;
      padding-left: 0;
      top: 49%;
      @media (max-width: 991px) {
        padding-right: 20px;
        padding-left: 20px;
      }
      @media (max-width: 479px) {
        position: static;
        @include transform(translateY(0%));
        padding-right: 10px;
        padding-left: 10px;
      }
    }
  }
}
/*=======================================================
                     Script Modules
=========================================================*/
@import "_menu.scss";
@import "_navbar.scss";
@import "_stuck-menu.scss";
@import "_to-top.scss";
@import "_parallax.scss";
@import "_tabs.scss";
//@import "_thumb.scss";
/*========================================================
                     HEADER  Section
=========================================================*/
header {
  background: #FFFFFF;
  padding: 15px 0;

  .brand{
   
  }
  .nav{
    
    padding: 15px 0;
    line-height: 1;
  }
  @media (max-width: 991px) {
    text-align: center;
    .brand, .nav{
      float: none;
    }
  }
  &.subpage-mod{
    border-bottom: 4px solid map-get($scheme, primary);
  }
}

/*========================================================
                     CONTENT  Section
=========================================================*/
main {
  display: block;
  background: #FFFFFF;
}

/*========================================================
                     FOOTER  Styles
=========================================================*/
footer {
  background: map-get($scheme, primary);
  color: #FFFFFF;
  position: relative;
  h1, h2, h3, h4, h5, h6, .heading-1, .heading-2, .heading-3, .heading-4, .heading-5, .heading-6 {
    color: #FFFFFF;
  }
  a:hover{
    color: lighten(map-get($scheme, contrast), 20%);
  }
  .footer-info{
    padding-top: 120px;
    padding-bottom: 91px;
    a[href^="tel:"]{
      font-size: 40px;
      font-family: $secondary-font;
      line-height: 50px;

      &:hover{
        color: lighten(map-get($scheme, contrast), 20%);
      }
    }
    address{
      font-size: 30px;
      line-height: 1.433333;
      font-weight: 400;
      text-transform: uppercase;
    }
    * + address{
      margin-top: 8px;
    }
    &__inset-2{
      padding-top: 100px;
    }
  }

  .copyright {
    font-size: 14px;
    margin-top: 29px;
  }
} 

