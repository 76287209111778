/*==================  GOOGLE MAP  ======================*/
$map-height: 440px;
$map-height-767: 250px;
$map-height-479: 200px;

.google-map-markers{
  display: none;
}

.google-map-container {
  width: 100%;
}

.map {
  position: absolute;
  right: 0;
  top: 0;
  @media (max-width: 1199px) {
    position: static;
  }

  .google-map{
    height: $map-height;
    width: 100%;
    color: #000;

    img {
      max-width: none !important;
    }

    @media (max-width: 767px) {
      height: $map-height-767;
    }

    @media (max-width: 479px) {
      height: $map-height-479;
    }
  }

  
}

