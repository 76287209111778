/*==================  RD Parallax    ======================*/

.parallax-content {
  position: relative;
  z-index: 1;
}

.parallax-container {
  position: relative;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
}

.material-parallax {
  position: absolute;
  top: 0;
  left: -1px;
  right: -1px;
  bottom: 0;
  z-index: 0;
  background-position: center center;
}

.ipad,
.iphone {
  .parallax-container {
    background-attachment: scroll !important;
  }
}

.material-parallax img {
  display: none;
  position: absolute;
  left: 50%;
  bottom: 0;
  min-width: 101%;
  min-height: 101%;
  max-width: none;
  transform: translate3d(-50%, 0, 0);
}

.parallax--mod:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(51, 51, 51, 0.8);
  z-index: 1;
}