/*==========================================================
                        NORMALIZE
==========================================================*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: top;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

button, 
input[type='button']{
  background: none;
  border: none;
  display: inline-block;
  margin: 0;
  padding: 0;
  outline: none;
  outline-offset: 0;
  overflow: visible;
  cursor: pointer;
  -webkit-appearance: none;

  &::-moz-focus-inner {
    border: none;
    padding: 0;
  }
}

input[type='text'],
input[type='time'],
input[type='url'],
input[type='month'],
input[type='week'],
input[type='email'],
input[type='color'],
input[type='datetime'],
input[type='datetime-local'],
input[type='range'],
input[type='search'],
input[type='tel'],
input[type='date'],
input[type='password'],
input[type='number']{
  outline: none;
  background-color: transparent;
  border: none;
  -webkit-appearance: none;
  border-radius: 0;
  vertical-align: baseline;
  box-shadow: none;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}