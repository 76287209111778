@import "_mixins.scss";
@import "_variables.scss";

$scheme: nth($schemes,1);
/*========================================================
                      Contact Form
=========================================================*/

$input-height: 50px;
$input-offset-top: 30px;
$input-padding: 15px 19px;
$input-font-family: $default-font;
$input-font-size: 16px;
$input-line-height: 20px;
$input-color: #888;
$input-border-radius: 0px;
$input-brdr: none;
$input-font-weight: 300;
$input-background: #fff;

$textarea-height: 300px;

$placeholder-clr: $input-color;
$placeholder-opacity: 1;

/* MF
========================================================*/
.mailform {
  position: relative;
  text-align: left;
  margin-left: auto;
  margin-right: auto;

  fieldset {
    border: none;
  }

  * {
    @include box-sizing(border-box);
  }
}


.form-wrap {
  position: relative;

  &.has-error {
    .form-input {
      border-color: #f00;
    }
  }

  &.has-focus {
    .form-input {
      border-color: #f00;
    }
  }
}


.rd-mailform {
  .row {
    > [class*='col-'] {
      margin-bottom: 30px;
    }
  }
  
  .form-input {
    display: block;
    width: 100%;
    margin: 0;
    -webkit-appearance: none;
    outline: none;
    font-family: $input-font-family;
    font-size: $input-font-size;
    padding: $input-padding;
    line-height: $input-line-height;
    color: $input-color;
    background: $input-background;
    border: 1px solid #fff;
    @include border-radius($input-border-radius);

    &:-moz-placeholder {
      color: $placeholder-clr;
      opacity: $placeholder-opacity;
    }

    &::-webkit-input-placeholder {
      color: $placeholder-clr;
      opacity: $placeholder-opacity;
    }

    &::-moz-placeholder {
      color: $placeholder-clr;
      opacity: $placeholder-opacity;
    }

    &:-ms-input-placeholder {
      color: $placeholder-clr;
      opacity: $placeholder-opacity;
    }
  }
  textarea.form-input {
    resize: vertical;
    overflow: auto;
    height: $textarea-height;
  }

  label.form-label {
    position: absolute;
    width: 100%;
    margin: 0;
    -webkit-appearance: none;
    outline: none;
    font-family: $input-font-family;
    font-size: $input-font-size;
    padding: $input-padding;
    line-height: $input-line-height;
    color: $placeholder-clr;
    top: 26px;
    left: 0;
    right: 0;
    pointer-events: none;
    text-align: left;
    z-index: 9;
    transition: .25s;
    will-change: transform;
    transform: translateY(-50%);

    &.focus {
      opacity: 0;
    }

    &.auto-fill {
      color: $placeholder-clr;
    }
  }
  
}

[data-x-mode='true'] {
  .form-label {
    pointer-events: auto;
  }
}




// Form output
#form-output-global {
  position: fixed;
  bottom: 30px;
  left: 15px;
  z-index: 2000;
  visibility: hidden;
  transform: translate3d(-500px, 0, 0);
  transition: .3s all ease;

  &.active {
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }

  @media (min-width: 768px){
    left: 30px;
  }
}

@import "mailform_modules/_mf-animation.scss";
@import "mailform_modules/_mf-placeholder.scss";
@import "mailform_modules/_mf-validation.scss";
@import "mailform_modules/_mf-submit.scss";
//@import "mailform_modules/_mf-icon.scss";
//@import "mailform_modules/_mf-select.scss";
//@import "mailform_modules/_mf-datepicker.scss";










