/* Mail Form PlaceHolder
========================================================*/
@mixin placeholder-fade-up() {
  *:-webkit-autofill ~ & {
    opacity: .0;
    @include transform(translateY(-33%) scale(0.8));
  }

  &.state-1 {
    opacity: .0;
    @include transform(translateY(-33%) scale(0.8));
  }
}

@mixin placeholder-fade-left() {
  *:-webkit-autofill ~ & {
    opacity: .4;
    @include transform(translateX(-100%));
  }

  &.state-1 {
    opacity: .4;
    @include transform(translateX(-100%));
  }

  @media (max-width: 1365px) {
    .container > .mailform &,
    .col-md-12 & {
      @include placeholder-fade-up;
    }
  }

  @media (max-width: 979px) {
    .col-md-10 & {
      @include placeholder-fade-up();
    }
  }

  @media (max-width: 767px) {
    @include placeholder-fade-up();
  }
}
@mixin placeholder-fade() {
  *:-webkit-autofill ~ & {
    opacity: .0;
  }

  &.state-1 {

    opacity: .0;
  }

  *:-webkit-autofill ~ &{
    display: none;
  }
  .mfInput.filled &.state-1{
    display: none;
  }
}

.mfPlaceHolder {
  font: inherit;
  cursor: text;
  position: absolute;
  left: 0;
  top: 0;
  padding: $input-padding;
  line-height: $input-line-height;
  color: $placeholder-clr;
  opacity: $placeholder-opacity;
  @include placeholder-fade();
  @include transition(.3s all ease);
}