/*=================       Tabs     ======================*/
.resp-tabs-list {
  li{
    cursor: pointer;
    display: inline-block;
    color: map-get($scheme, primary);
    font-weight: 500;
    @include transition(.3s);
    & + li{
      margin-left: 26px;
      @media (max-width: 991px) {
        margin-left: 10px;
      }
    }

    &:hover{
      color: lighten(map-get($scheme, contrast), 20%);
    }

    &.resp-tab-active{
      color: lighten(map-get($scheme, contrast), 20%);
    }
  }
}

* + .resp-tabs-container{
  margin-top: 32px;
}

.resp-accordion {
  display: none;
}

.resp-tab-content {
  display: none;
}
.resp-tab-content-active {
  display: block;
}

@media (max-width: 767px) {
  .resp-accordion {
    display: block;
    background: map-get($scheme, primary);
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
    font-size: 20px;
    @include transition(.3s);

    &:hover{
      background: darken(map-get($scheme, primary), 10%);
    }
    &.resp-tab-active{
      background: darken(map-get($scheme, primary), 10%);
    }
  }

  .resp-tabs-list {
    display: none;
  }
}