/*
* MF Validation
*/

$rd-validation-color: #f00;


//
// Form validation
//
.form-validation {
  position: absolute;
  right: 8px;
  top: 0;
  z-index: 11;
  margin-top: 2px;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0;
  color: $rd-validation-color;
  transition: .3s;
}

.form-validation-left {
  .form-validation {
    top: 100%;
    right: auto;
    left: 0;
  }
}
