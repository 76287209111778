/*==================  Stuck menu  ======================*/
$stuck-bg: #fff;

.stuck_container {
  > .container {
    display: flex;
    justify-content: space-between;
  }
  
}

.isStuck {
  background: $stuck-bg;
  z-index: 999;
  border-bottom: 4px solid map-get($scheme, primary);
}

@media only screen and (max-width: 991px) {
  .isStuck {
    display: none !important;
  }
}