//==========================================================
//                        MIXINS
//==========================================================

// Note: Some of described mixins in this file requires the ie conditional comments
//
// For IE < 9
// <!--[if lt IE 9]>
//    <html class='lt-ie9'>
// <![endif]-->
//

@mixin keyframes($name){
  @-ms-keyframes #{$name} {
    @content;
  }

  @-o-keyframes #{$name} {
    @content;
  }

  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

@mixin clip($x1, $x2, $y1, $y2){
    clip: rect($y1,$x2,$y2,$x1);
}

// Opacity
@mixin opacity($opacity) {
  opacity: $opacity;

  .lt-ie9 & {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$opacity})";
    filter: alpha(opacity=$opacity*100);
  }
}

// Background-rgba
@mixin background($rgb, $opacity) {
  background: rgba($rgb, $opacity);

  .lt-ie9 & {
    background: none;
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str(rgba($rgb, $opacity))}', endColorstr='#{ie-hex-str(rgba($rgb, $opacity))}')";
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str(rgba($rgb, $opacity))}', endColorstr='#{ie-hex-str(rgba($rgb, $opacity))}');
    zoom: 1;
  }
}

// Transition
@mixin transition($trsn) {
  -moz-transition: $trsn;
  -o-transition: $trsn;
  -webkit-transition: $trsn;
  transition: $trsn;
}

// Transition Delay
@mixin transition-delay($trsn-d) {
  -moz-transition-delay: $trsn-d;
  -o-transition-delay: $trsn-d;
  -webkit-transition-delay: $trsn-d;
  transition-delay: $trsn-d;
}

// Animation
@mixin animation($anim) {
  -webkit-animation: $anim;
  -moz-animation: $anim;
  -ms-animation: $anim;
  -o-animation: $anim;
  animation: $anim;
}

// Transform Origin
@mixin transform-origin($trfm-o) {
  -moz-transform-origin: $trfm-o;
  -ms-transform-origin: $trfm-o;
  -o-transform-origin: $trfm-o;
  -webkit-transform-origin: $trfm-o;
  transform-origin: $trfm-o;
}

// Transform
@mixin transform($trfm) {
  -moz-transform: $trfm;
  -ms-transform: $trfm;
  -o-transform: $trfm;
  -webkit-transform: $trfm;
  transform: $trfm;
}

// Box Sizing
@mixin box-sizing($bs) {
  -moz-box-sizing: $bs;
  -webkit-box-sizing: $bs;
  box-sizing: $bs;
}

// Border Radius
@mixin border-radius($border-radius) {
  -webkit-border-radius: $border-radius;
  -moz-border-radius: $border-radius;
  border-radius: $border-radius;
}

// Box Shadow
@mixin box-shadow($box-shadow) {
  -webkit-box-shadow: $box-shadow;
  -moz-box-shadow: $box-shadow;
  box-shadow: $box-shadow;
}

// Reset Input
@mixin reset-input() {
  outline: none;
  background-color: transparent;
  border: none;
  -webkit-appearance: none;
  border-radius: 0;
  vertical-align: baseline;
  box-shadow: none;
}

// Hide Text
@mixin hide-text {
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
}

// User Select
@mixin user-select($select: none) {
  -webkit-user-select: $select;
  -khtml-user-select: $select;
  -moz-user-select: $select;
  -ms-user-select: $select;
  user-select: $select;
}

// Triangle
@mixin triangle($dir, $w, $h, $clr) {
  width: 0;
  height: 0;
  border-style: solid;

  @if $dir == top {
    border-width: 0 $w/2 $h $w/2;
    border-color: transparent transparent $clr transparent;
  } @else if $dir == bottom {
    border-width: $h $w/2 0 $w/2;
    border-color: $clr transparent transparent transparent;
  } @else if $dir == left {
    border-width: $h/2 $w $h/2 0;
    border-color: transparent $clr transparent transparent;
  } @else if $dir == right {
    border-width: $h/2 0 $h/2 $w;
    border-color: transparent transparent transparent $clr;
  } @else if $dir == top_left {
    border-width: $w $w 0 0;
    border-color: $clr transparent transparent transparent;
  } @else if $dir == top_right {
    border-width: 0 $w $w 0;
    border-color: transparent $clr transparent transparent;
  } @else if $dir == bottom_right {
    border-width: 0 0 $w $w;
    border-color: transparent transparent $clr transparent;
  } @else if $dir == bottom_left {
    border-width: $w 0 0 $w;
    border-color: transparent transparent transparent $clr;
  }
}

// Clearfix
@mixin clearfix {
  &:before,
  &:after {
    display: table;
    content: "";
    line-height: 0;
  }

  &:after {
    clear: both;
  }
}

// Variables
$fa: 'FontAwesome';

// FA Icons
// Angle
$fa-angle-left: '\f104';
$fa-angle-right: '\f105';
$fa-angle-down: '\f107';
$fa-angle-up: '\f106';

// Arrow Circle
$fa-arrow-circle-down: '\f0ab';
$fa-arrow-circle-top: '\f0aa';
$fa-arrow-circle-left: '\f0a8';
$fa-arrow-circle-right: '\f0a9';

// Caret
$fa-caret-down: '\f0d7';
$fa-caret-right: '\f0da';
$fa-caret-left: '\f0d9';
$fa-caret-up: '\f0d8';

// Chevron
$fa-chevron-left: '\f053';
$fa-chevron-right: '\f054';
$fa-chevron-up: '\f077';
$fa-chevron-down: '\f078';

// Other
$fa-search: '\f002';
$fa-close: '\f00d';
$fa-expand: '\f065';
$fa-play-circle: '\f144';
$fa-play-circle-o: '\f01d';
$fa-play: '\f04b';
$fa-youtube-play: '\f16a';
$fa-link: '\f0c1';
$fa-envelope: '\f0e0';
$fa-mobile: '\f10b';
$fa-map-marker: '\f041';
$fa-comments: '\f086';
$fa-comments-o: '\f0e6';
$fa-cogs: '\f085';
$fa-cog: '\f013';
$fa-eye: '\f06e';
$fa-external-link: '\f08e';
$fa-photo: '\f03e';
$fa-volume-off: '\f026';
$fa-volume-up: '\f028';
$fa-volume-down: '\f027';
$fa-users: '\f0c0';
$fa-times: '\f00d';
$fa-star: '\f005';
$fa-stop: '\f04d';
$fa-pause: '\f04c';
$fa-check-square-o: '\f046';
$fa-desktop: '\f108';
$fa-cubes: '\f1b3';
$fa-bolt: '\f0e7';
$fa-bullhorn: '\f0a1';
$fa-book: '\f02d';
$fa-gears: '\f085';
$fa-codepen: '\f1cb';
$fa-plus: '\f067';
$fa-thumbs-o-up: '\f087';
$fa-quote-left: '\f10d';

