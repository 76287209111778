//========================================================
//                     Variables
//=========================================================

$scheme-1: (
    primary: #67acbc,
    contrast: #000,
    default: #fff
);

$scheme-2: (
    primary: #2196F3,
    contrast: #000,
    default: #fff
);

$scheme-3: (
    primary: #59b791,
    contrast: #000,
    default: #fff
);

$scheme-4: (
    primary: #b145ad,
    contrast: #000,
    default: #fff
);

$scheme-5: (
    primary: #645fce,
    contrast: #000,
    default: #fff
);

$schemes: ($scheme-1, $scheme-2, $scheme-3, $scheme-4, $scheme-5);

// Fonts
$default-font: 'Raleway', sans-serif;
$secondary-font: 'Montserrat', sans-serif;

$border-radius-base: 3px;
$border-radius-large: 5px;

$box-shadow-ambient: 0 0 2px 0 rgba(#000, .11);
$box-shadow-key-light: 3px 4px 5px 0 rgba(#000, .11);

$screen-sm-min: 768px;
