/*========================   Menu   ==========================*/

//--------------------------------------------------------------
//                       Menu Styles
//--------------------------------------------------------------
.sf-menu {
  @include clearfix;
  display: inline-block;

  > li {
    position: relative;
    float: left;

    & + li {
      margin-left: 32px;
    }
  }

  a {
    display: block;
  }
}

//--------------------------------------------------------------
//                     Menu item styles
//--------------------------------------------------------------
.sf-menu {
  > li {
    > a {
      font-size: 15px;
      line-height: 20px;
      font-weight: 700;
      color: map-get($scheme, primary);
      text-transform: uppercase;
    }

    &.sfHover > a, > a:hover {
      color: lighten(map-get($scheme, contrast), 20%);
    }

    &.active > a {
      color: lighten(map-get($scheme, contrast), 20%);
    }
  }
}

//--------------------------------------------------------------
//                   Sub menu lvl1 styles
//--------------------------------------------------------------
.sf-menu ul {
  display: none;
  position: absolute;
  top: 100%;
  left: -10px;
  width: 150px;
  margin-top: 15px;
  padding: 20px 0;
  background: #333;
  z-index: 3;
  @include box-sizing(border-box);
  text-align: left;
  @include box-shadow(0px 0px 5px rgba(#000, .4));
}

//--------------------------------------------------------------
//                 Sub menu lvl1 item styles
//--------------------------------------------------------------
.sf-menu ul {
  > li {
    > a {
      color: #FFF;
      font-size: 15px;
      line-height: 30px;
      font-weight: 500;
      padding: 0 15px;
    }

    &.sfHover > a, > a:hover {
      color: #FFF;
      background: map-get($scheme, primary);
    }
  }
}

//--------------------------------------------------------------
//                   Sub menu lvl2 styles
//--------------------------------------------------------------
.sf-menu ul ul {
  position: absolute;
  top: 23%;
  left: 102%;
  width: 150px;
  margin-top: 0;
  z-index: 4;
  padding: 20px 0;
  background: #333;
}

//--------------------------------------------------------------
//                 Sub menu lvl2 item styles
//--------------------------------------------------------------
.sf-menu ul ul {
  > li {
    > a {
    }

    &.sfHover > a, > a:hover {
    }
  }
}

//--------------------------------------------------------------
//                      sf-with-ul styles
//--------------------------------------------------------------
.sf-menu > li > .sf-with-ul {
  position: relative;

  &:before {
    position: absolute;
    left: 50%;
    top: 65%;
    margin-left: -6px;
    content: $fa-caret-down;
    font: 400 12px/12px $fa;
  }
}

//--------------------------------------------------------------
//                        Responsive
//--------------------------------------------------------------
@media (max-width: 991px) {
  .sf-menu {
    display: none;
  }
}
