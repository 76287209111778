/*==================     ToTop     ======================*/
.ui-to-top {
  width: 50px;
  height: 50px;
  font-size: 24px;
  line-height: 46px;
  color: #fff;
  background: map-get($scheme, primary);
  border-radius: 50%;
  @include box-shadow(4px 4px 8px 0 rgba(#000, .4));

  position: fixed; 
  right: 40px;
  bottom: 40px;

  
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  z-index: 20;
  transition: .45s all ease-in-out;
  transform: translate3d(0, 100px, 0);

  &:hover {
    color: #fff;
    background: lighten(map-get($scheme, contrast), 20%);
    text-decoration: none;
  }
  &.active {
    transform: translate3d(0, 0, 0);
  }
}

.mobile .ui-to-top, 
.tablet .ui-to-top{
	display: none!important; 
}


